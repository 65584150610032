import React, { memo } from "react";
import MailFooter from "../components/MailFooter";
import MailHeader from "../components/MailHeader";

const MailToCustomer = memo(() => {
    return (
      <div style={{ width: "600px", backgroundColor: "#FFF" }}>
        <MailHeader />

        <div style={{ padding: "64px 60px" }}>
          <div
            style={{
              fontFamily: "Arial",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#0F172A",
            }}
          >
            Благодарим за обращение в SeaLogic!
          </div>

          <div
            style={{
              fontFamily: "Arial",
              fontWeight: "normal",
              fontsize: "12px",
              color: "#334155",
              marginTop: "12px",
            }}
          >
            Мы начали просчет по вашей заявке и уже готовим для вас ценовое
            предложение. Ожидайте ответ в ближайшее время
          </div>

          <div
            style={{
              marginTop: "44px",
              fontFamily: "Arial",
              fontSize: "9px",
              lineHeight: "14px",
              color: "#94A3B8",
            }}
          >
            *Данное письмо сформировано автоматически. Отвечать на него не
            нужно.
          </div>
        </div>

        <MailFooter />
      </div>
    );
});

export default MailToCustomer;
