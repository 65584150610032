import React, { memo } from "react";
import { renderToString } from "react-dom/server";

const MailBody = memo((props) => {
    return (
        <div>
            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "normal", fontSize: "12px", lineHeight: "16px", alignItems: "center", color: "#334155"}}>
                <b style={{fontWeight: "600", color: "#0F172A", marginRight: "10px"}}>Имя</b> {props.name}
            </div>

            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "normal", fontSize: "12px", lineHeight: "16px", alignItems: "center", color: "#334155", marginTop: "12px"}}>
                <b style={{fontWeight: "600", color: "#0F172A", marginRight: "10px"}}>Email</b> {props.email}
            </div>

            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "normal", fontSize: "12px", lineHeight: "16px", alignItems: "center", color: "#334155", marginTop: "12px"}}>
                <b style={{fontWeight: "600", color: "#0F172A", marginRight: "10px"}}>Телефон</b> {props.phone}
            </div>

            {props.noComment
                ? ''
                : <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "normal", fontSize: "12px", lineHeight: "16px", alignItems: "center", color: "#334155", marginTop: "12px"}}>
                    <b style={{fontWeight: "600", color: "#0F172A", marginRight: "10px"}}>Комментарий</b> <p style={{marginTop: "12px", marginBottom: "0"}} dangerouslySetInnerHTML={{__html: props.comment?.replace(/\n/, renderToString(<br/>))}}></p>
                </div>
            }
        </div>
    );
});

export default MailBody;
