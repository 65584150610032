import React, { memo } from "react";
import { renderToString } from "react-dom/server";
import MailHeader from "../components/MailHeader";
import MailFooter from "../components/MailFooter";

const MailTemplateToCustomer = memo((props) => {
    return (
      <div style={{ width: "600px", backgroundColor: "#FFF" }}>
        <MailHeader />

        <div style={{ padding: "64px 60px" }}>
          <div
            style={{
              fontFamily: ["Montserrat", "Arial"],
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#0F172A",
            }}
          >
            Благодарим за обращение в SeaLogic!
          </div>

          <p
            style={{
              fontFamily: ["Montserrat", "Arial"],
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: "#334155",
              marginTop: "12px",
              marginBottom: "0px"
            }}
            dangerouslySetInnerHTML={{__html: props.text?.replace(/\n/, renderToString(<br/>))}}
          ></p>

          <div
            style={{
              marginTop: "44px",
              fontFamily: ["Montserrat", "Arial"],
              fontSize: "9px",
              lineHeight: "14px",
              color: "#94A3B8",
            }}
          >
            *Данное письмо сформировано автоматически. Отвечать на него не
            нужно.
          </div>
        </div>

        <MailFooter />
      </div>
    );
});

export default MailTemplateToCustomer;
