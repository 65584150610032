import React, { memo } from "react";
import { renderToString } from "react-dom/server";
import MailHeader from "../components/MailHeader";
import MailFooterEmpty from "../components/MailFooterEmpty";

const MailFeedback = memo((props) => {

    return <div style={{width: "600px",backgroundColor: "#FFF"}}>
        <MailHeader />

        <div style={{padding: "64px 60px"}}>
            <div style={{fontFamily: "Arial", textTransform: "uppercase", fontWeight: "600", fontSize: "18px", lineHeight: "28px", color: "#0F172A", textAlign: "center", marginBottom: "66px"}}>
                Обратная связь:
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Имя: <b>{props.name}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                e-mail: <b>{props.email}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Телефон: <b>{props.phone}</b>
            </div>

            <div style={{fontFamily: "Arial", fontWeight: "normal", fontsize: "12px", lineHeight: "142%", alignItems: "center", color: "#000000", marginTop: "4px"}}>
                Комментарий: <br/><b style={{marginLeft: "20px", display: "block"}} dangerouslySetInnerHTML={{__html: props.comment?.replace(/\n/, renderToString(<br />))}} />
            </div>

            <div style={{marginTop: "44px", fontFamily: "Arial", fontSize: "9px", lineHeight: "14px", color: "#94A3B8"}}>
                *Данное письмо сформировано автоматически. Отвечать на него не нужно.
            </div>
        </div>

        <MailFooterEmpty />
    </div>
});

export default MailFeedback;
