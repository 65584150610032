import React, { memo } from "react";
import MailHeader from "../components/MailHeader";
import MailFooter from "../components/MailFooter";

const MailRequestTariffToCustomer = memo(() => {
    return (
      <div style={{ width: "600px", backgroundColor: "#FFF" }}>
        <MailHeader />

        <div style={{ padding: "64px 60px" }}>
          <div
            style={{
              fontFamily: "Arial",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#0F172A",
            }}
          >
            Благодарим за обращение в SeaLogic!
          </div>

          <div
            style={{
              fontFamily: "Arial",
              fontWeight: "normal",
              fontsize: "12px",
              color: "#334155",
              marginTop: "12px",
            }}
          >
            Наш менеджер свяжется с вами в ближайшее время для подтверждения
            подписки
          </div>

          <div
            style={{
              marginTop: "44px",
              fontFamily: "Arial",
              fontSize: "9px",
              lineHeight: "14px",
              color: "#94A3B8",
            }}
          >
            *Данное письмо сформировано автоматически. Отвечать на него не
            нужно.
          </div>
        </div>

        <MailFooter />
      </div>
    );
});

export default MailRequestTariffToCustomer;
