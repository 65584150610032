import React, { memo } from "react";
import MailHeader from "../components/MailHeader";
import MailFooter from "../components/MailFooter";

const MailCurrentRatesToCustomerEn = memo((props) => {
    return (
      <div style={{ width: "600px", backgroundColor: "#FFF" }}>
        <MailHeader />

        <div style={{ padding: "64px 60px" }}>
          <div
            style={{
              fontFamily: ["Montserrat", "Arial"],
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#0F172A",
            }}
          >
              Thank you for contacting SeaLogic!
          </div>

          <p
            style={{
              fontFamily: ["Montserrat", "Arial"],
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: "#334155",
              marginTop: "12px",
              marginBottom: "0px"
            }}
          >
            You have been provided with a file containing demo rates!
          </p>
          <p
            style={{
                fontFamily: ["Montserrat", "Arial"],
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#334155",
                marginTop: "12px",
                marginBottom: "0px"
            }}
          >
            If you have any queries or would like to be notified of current rates on a regular basis, please get in touch with us.
            By email at <a
              href="mailto:info@sealogic.io"
              target="_blank"
              rel=" noopener noreferrer"
              style={{
                  fontFamily: ["Montserrat", "Arial"],
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#2563EB",
              }}
            >info@sealogic.io</a> or by submitting a request via the website at <a
              href="https://sealogic.io/"
              target="_blank"
              rel=" noopener noreferrer"
              style={{
                  fontFamily: ["Montserrat", "Arial"],
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#2563EB",
              }}
          >sealogic.io</a>
          </p>

          <div
            style={{
              marginTop: "44px",
              fontFamily: ["Montserrat", "Arial"],
              fontSize: "9px",
              lineHeight: "14px",
              color: "#94A3B8",
            }}
          >
            *This message was automatically generated. Please do not reply to it
          </div>
        </div>

        <MailFooter />
      </div>
    );
});

export default MailCurrentRatesToCustomerEn;
