import React, { memo } from "react";
import MailHeader from "../components/MailHeader";
import MailFooterTr from "../components/MailFooterTr";

const MailToCustomerTr = memo(() => {
    return (
      <div style={{ width: "600px", backgroundColor: "#FFF" }}>
        <MailHeader />

        <div style={{ padding: "64px 60px" }}>
          <div
            style={{
              fontFamily: "Arial",
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#0F172A",
            }}
          >
            SeaLogic ile iletişime geçtiğiniz için teşekkür ederiz!
          </div>

          <div
            style={{
              fontFamily: "Arial",
              fontWeight: "normal",
              fontsize: "12px",
              color: "#334155",
              marginTop: "12px",
            }}
          >
            Talebinizi hesaplamaya başladık ve sizin için bir fiyat teklifi
            hazırlıyoruz. Mümkün olan en kısa sürede sizinle iletişime geçeceğiz
          </div>

          <div
            style={{
              marginTop: "44px",
              fontFamily: "Arial",
              fontSize: "9px",
              lineHeight: "14px",
              color: "#94A3B8",
            }}
          >
            *Bu mektup otomatik olarak oluşturulur. Cevap vermenize gerek yoktur
          </div>
        </div>

        <MailFooterTr />
      </div>
    );
});

export default MailToCustomerTr;
