import React, { memo } from "react";
import MailHeader from "../components/MailHeader";
import MailBody from "../components/MailBody";
import MailFooterEmpty from "../components/MailFooterEmpty";
import MailPostscript from "../components/MailPostscript";

const MailRequestGeneral = memo((props) => {
    return <div style={{width: "600px",backgroundColor: "#FFF"}}>
        <MailHeader />

        <div style={{padding: "64px 60px"}}>
            <div style={{fontFamily: ["Montserrat", "Arial"], fontWeight: "600", fontSize: "18px", lineHeight: "28px", color: "#0F172A", textAlign: "left", marginBottom: "44px"}}>
                {props.title || 'Обратная связь'}
            </div>

            <MailBody
                name={props.name}
                email={props.email}
                phone={props.phone}
                comment={props.comment}
                noComment={props.noComment}
            />

            <MailPostscript />
        </div>

        <MailFooterEmpty />
    </div>
});

export default MailRequestGeneral;
