import React, { memo } from "react";

const MailPostscript = memo(() => {
    return (
        <div style={{marginTop: "44px", fontFamily: ["Montserrat", "Arial"], fontSize: "9px", lineHeight: "14px", color: "#94A3B8"}}>
            *Данное письмо сформировано автоматически. Отвечать на него не нужно.
        </div>
    );
});

export default MailPostscript;
