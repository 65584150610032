import axios from "axios";
import { getApiHost, getUserIp } from "../misc/api";
import {
    CITIES_DATA,
    CITIES_FETCHED,
    CITIES_FETCHING,
    COMPANIES_DATA,
    COMPANIES_FETCHED,
    COMPANIES_FETCHING,
    PORTS_DATA,
    PORTS_FETCHED,
    PORTS_FETCHING,
    ROUTES_CLEAR,
    ROUTES_FETCHED,
    ROUTES_FETCHING,
    ROUTES_SEARCH,
    SEARCH_INIT_POD,
    SEARCH_INIT_POL,
} from "./types";
import { v4 as uuidv4 } from 'uuid';
import sha256 from "sha256";

const apiHost = getApiHost();

export const api_searchRoutes = (pol, pod, date) => async (dispatch) => {
    let data = null;
    try {
        dispatch({
            type: ROUTES_FETCHING,
            payload: true
        });

        const ip = await getUserIp();
        data = (await axios.get(`${apiHost}/api/routes?pol=${pol}&pod=${pod}&date=${date}&ip=${ip}`)).data;
    }
    catch(e) {
        dispatch({
            type: ROUTES_FETCHING,
            payload: false
        });

        dispatch({
            type: ROUTES_FETCHED,
            payload: false
        });
        return Promise.reject(e);
    }

    dispatch({
        type: ROUTES_SEARCH,
        payload: data
    });

    dispatch({
        type: ROUTES_FETCHING,
        payload: false
    });

    dispatch({
        type: ROUTES_FETCHED,
        payload: true
    });

    return Promise.resolve(data);
}

export const api_clearRoutes = () => async (dispatch) => {
    dispatch({
        type: ROUTES_CLEAR,
        payload: {}
    });

    return Promise.resolve(true);
}

export const api_getCompanies = () => async (dispatch) => {
    let data = null;
    try {
        dispatch({
            type: COMPANIES_FETCHING,
            payload: true
        });

        data = (await axios.get(`${apiHost}/api/companies`)).data;
    }
    catch(e) {
        dispatch({
            type: COMPANIES_FETCHING,
            payload: false
        });

        dispatch({
            type: COMPANIES_FETCHED,
            payload: false
        });
        return Promise.reject(e);
    }

    dispatch({
        type: COMPANIES_DATA,
        payload: data
    });

    dispatch({
        type: COMPANIES_FETCHING,
        payload: false
    });

    dispatch({
        type: COMPANIES_FETCHED,
        payload: true
    });

    return Promise.resolve(data);
}

export const api_getCompanies_notRedux = async () => {
    try {
        return (await axios.get(`${apiHost}/api/companies`)).data;
    }
    catch(e) {
        return [];
    }
}

export const api_getPorts = () => async (dispatch) => {
    let data = null;
    try {
        dispatch({
            type: PORTS_FETCHING,
            payload: true
        });

        data = (await axios.get(`${apiHost}/api/ports`)).data;
    }
    catch(e) {
        dispatch({
            type: PORTS_FETCHING,
            payload: false
        });

        dispatch({
            type: PORTS_FETCHED,
            payload: false
        });
        return Promise.reject(e);
    }

    dispatch({
        type: PORTS_DATA,
        payload: data
    });

    dispatch({
        type: PORTS_FETCHING,
        payload: false
    });

    dispatch({
        type: PORTS_FETCHED,
        payload: true
    });

    return Promise.resolve(data);
}

export const api_getCities = () => async (dispatch) => {
    let data = null;
    try {
        dispatch({
            type: CITIES_FETCHING,
            payload: true
        });

        data = (await axios.get(`${apiHost}/api/cities`)).data;
    }
    catch(e) {
        dispatch({
            type: CITIES_FETCHING,
            payload: false
        });

        dispatch({
            type: CITIES_FETCHED,
            payload: false
        });
        return Promise.reject(e);
    }

    dispatch({
        type: CITIES_DATA,
        payload: data
    });

    dispatch({
        type: CITIES_FETCHING,
        payload: false
    });

    dispatch({
        type: CITIES_FETCHED,
        payload: true
    });

    return Promise.resolve(data);
}

export const api_searchInit = () => async (dispatch) => {
    let data = [];
    try {
        data = (await axios.get(`${apiHost}/api/ports/initAutocomplete`)).data;
    }
    catch(e) {
        return Promise.reject(e);
    }

    dispatch({
        type: SEARCH_INIT_POL,
        payload: data.POL
    });

    dispatch({
        type: SEARCH_INIT_POD,
        payload: data.POD
    });

    return Promise.resolve(data);
}

export const api_getVersion = async () => {
    try {
        const data = (await axios.get(`${apiHost}/api/version`)).data;
        console.log(`App BACKEND version: ${data.version}. Updated at: ${data.date}.`);
    }
    catch(e) {

    }
}

export const api_mailSend = async (sbj, body, to = null, type = "text", template = null) => {
    try {
        const data = (await axios.post(`${apiHost}/api/mail/send`, {
            sbj,
            body,
            to,
            type,
            template,
        })).data;
        return data;
    }
    catch(e) {
        return false;
    }
}

export const api_uploadRoutes = async (formData) => {
    try {
        const data = (await axios.post(`${apiHost}/api/admin/upload/routes`, formData, {
            headers: {
                token: localStorage.getItem("token"),
                "Content-type": "multipart/form-data"
            }
        })).data;
        return data;
    }
    catch(e) {
        return false;
    }
}

export const api_uploadCompanies = async (formData) => {
    try {
        const data = (await axios.post(`${apiHost}/api/admin/upload/companies`, formData, {
            headers: {
                token: localStorage.getItem("token"),
                "Content-type": "multipart/form-data"
            }
        })).data;
        return data;
    }
    catch(e) {
        return false;
    }
}

export const api_uploadPorts = async (formData) => {
    try {
        const data = (await axios.post(`${apiHost}/api/admin/upload/ports`, formData, {
            headers: {
                token: localStorage.getItem("token"),
                "Content-type": "multipart/form-data"
            }
        })).data;
        return data;
    }
    catch(e) {
        return false;
    }
}

export const api_uploadCities = async (formData) => {
    try {
        const data = (await axios.post(`${apiHost}/api/admin/upload/cities`, formData, {
            headers: {
                token: localStorage.getItem("token"),
                "Content-type": "multipart/form-data"
            }
        })).data;
        return data;
    }
    catch(e) {
        return false;
    }
}

export const api_uploadLogos = async (formData) => {
    try {
        const data = (await axios.post(`${apiHost}/api/admin/upload/logos`, formData, {
            headers: {
                token: localStorage.getItem("token"),
                "Content-type": "multipart/form-data"
            }
        })).data;
        return data;
    }
    catch(e) {
        return false;
    }
}

export const api_getActualRoutes = async () => {
    try {
        const data = (await axios.get(`${apiHost}/api/routes/actual`)).data;
        return data;
    }
    catch(e) {
        return false;
    }
}

export const api_getSearchLogs = async (filter) => {
    try {
        const query = [];
        for (const key in filter)
            query.push(`${key}=${filter[key]}`);

        const data = (await axios.get(`${apiHost}/api/routes/searchLogs${query.length ? `?${query.join("&")}` : ""}`)).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_getPortsByQuery = async (query) => {
    try {
        const data = (await axios.get(`${apiHost}/api/ports/search/${query.trim()}`)).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_getPortsOnlyByQuery = async (query) => {
    try {
        const data = (await axios.get(`${apiHost}/api/ports/only/search/${query.trim()}`)).data;
        return data;
    }
    catch(e) {
        return [];
    }
}

export const api_authorize = async (email, password) => {
    try {
        const hash = uuidv4();
        const hashPassword = sha256(`${email}-${hash}-${password}`);
        const data = (await axios.post(`${apiHost}/api/user/authorize`, {
            email,
            password: hashPassword,
        }, {
            headers: {
                token: hash
            }
        })).data;

        if (data && typeof data === "string" && data.trim() !== "") {
            localStorage.setItem("token", data);
            return true;
        }
        return false;
    }
    catch(e) {}
    return false;
}

export const api_checkAuthorization = async () => {
    try {
        await axios.post(`${apiHost}/api/user/checkAuthorization`, {}, {
            headers: {
                token: localStorage.getItem("token"),
            }
        });

        return true;
    }
    catch(e) {}
    return false;
}

export const api_addCompany = async (data) => {
    try {
        return (await axios.post(`${apiHost}/api/companies`, data, {
            headers: {
                token: localStorage.getItem("token"),
            }
        })).data;
    }
    catch(e) {}
    return null;
}

export const api_saveCompany = async (id, data) => {
    try {
        return (await axios.put(`${apiHost}/api/companies/${id}`, data, {
            headers: {
                token: localStorage.getItem("token"),
            }
        })).data;
    }
    catch(e) {}
    return null;
}

export const api_deleteCompany = async (id) => {
    try {
        return (await axios.delete(`${apiHost}/api/companies/${id}`, {
            headers: {
                token: localStorage.getItem("token"),
            }
        })).data;
    }
    catch(e) {}
    return null;
}

export const api_getCompaniesPartners = async (id) => {
    try {
        return (await axios.get(`${apiHost}/api/companies/partners`, {
            headers: {
                token: localStorage.getItem("token"),
            }
        })).data;
    }
    catch(e) {}
    return [];
}
