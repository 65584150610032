import React, { useState } from "react";
import {Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import "./style.scss";
import { getLanguage, getLocalizeText } from "../../../misc/localization";
import { useLocation } from "react-router-dom";
import { getRuleCheckboxLabel } from "../../../misc/dom";
import { isValidEmail } from "../../../misc/mail";
import { api_addReqLog } from "../../../redux/actions/reqLog";

const GeneralForm = (props) => {
    const location = useLocation();

    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [rule, setRule] = useState(false);

    const [errName, setErrName] = useState(false);
    const [errPhone, setErrPhone] = useState(false);
    const [errEmail, setErrEmail] = useState(false);
    const [errRule, setErrRule] = useState(false);

    const [helperEmail, setHelperEmail] = useState("");
    const [helperName, setHelperName] = useState("");
    const [helperPhone, setHelperPhone] = useState("");
    const [helperRule, setHelperRule] = useState("");

    const [nameSuccess, setNameSuccess] = useState(false);
    const [phoneSuccess, setPhoneSuccess] = useState(false);
    const [emailSuccess, setEmailSuccess] = useState(false);
    const [ruleSuccess, setRuleSuccess] = useState(false);

    const changeName = (e) => {
        setName(e.target.value);
    }

    const changePhone = (e) => {
        setPhone(e.target.value);
    }

    const changeEmail = (e) => {
        setEmail(e.target.value);
    }

    const changeRule = (e) => {
        setRule(e.target.checked);
    }

    const validate = () => {
        resetErrors();

        let ok = true;
        if (!rule) {
            ok = false;
            setHelperRule(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrRule(true);
        }
        else {
            setRuleSuccess(true);
        }

        if (!name.trim().length) {
            ok = false;
            setHelperName(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrName(true);
        }
        else {
            setNameSuccess(true);
        }

        if (!phone.trim().length) {
            ok = false;
            setHelperPhone(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrPhone(true);
        }
        else {
            setPhoneSuccess(true);
        }

        if (!email.trim().length) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillField", getLanguage(location)));
            setErrEmail(true);
        }
        else if (!isValidEmail(email)) {
            ok = false;
            setHelperEmail(getLocalizeText("dialogs/main", "fillFieldCorrectly", getLanguage(location)));
            setErrEmail(true);
        }
        else {
            setEmailSuccess(true);
        }

        return ok;
    }

    const resetErrors = () => {
        setErrName(false);
        setErrPhone(false);
        setErrEmail(false);
        setErrRule(false);

        setNameSuccess(false);
        setEmailSuccess(false);
        setPhoneSuccess(false);
        setRuleSuccess(false);

        setHelperName("");
        setHelperPhone("");
        setHelperEmail("");
        setHelperRule("");
    }

    const onSend = async (e) => {
        e.preventDefault();

        if (validate()) {
            if (typeof window.ym === "function")
                window.ym(89710989,'reachGoal','generaForm');

            const data = {
                name,
                email,
                phone,
            }
            // Save to logs
            const reqLog = {
                type: "generalForm",
                data
            };
            await api_addReqLog(reqLog);

            send(data);

            setName("");
            setEmail("");
            setPhone("");
            setRule(false);
            resetErrors();

            return false;
        }
    }

    const send = (data) => {
        if (typeof props.onSend === "function")
            props.onSend(data);
    }

    return <div className="GeneralForm">
        {props.title ? <h3 className="h3">{props.title}</h3> : ''}
        <div className="row">
            <div className="label">{getLocalizeText("dialogs/main", "name", getLanguage(location))}<div className="req">*</div></div>
            <div>
                <TextField
                    fullWidth
                    required
                    error={errName}
                    type="text"
                    helperText={helperName}
                    placeholder={getLocalizeText("dialogs/main", "placeholderName", getLanguage(location))}
                    value={name} onChange={changeName}
                    className={`${errName ? "error" : ""} ${nameSuccess ? "success" : ""}`}
                />
            </div>
        </div>
        <div className="row">
            <div className="label">{getLocalizeText("dialogs/main", "email", getLanguage(location))}<div className="req">*</div></div>
            <div>
                <TextField
                    value={email}
                    fullWidth
                    required
                    error={errEmail}
                    type="text"
                    helperText={helperEmail}
                    className={`${errEmail ? "error" : ""} ${emailSuccess ? "success" : ""}`}
                    placeholder={getLocalizeText("dialogs/main", "placeholderEmail", getLanguage(location))}
                    onChange={changeEmail}
                />
            </div>
        </div>

        <div className="row">
            <div className="label">{getLocalizeText("dialogs/main", "phone", getLanguage(location))}<div className="req">*</div></div>
            <div>
                <TextField
                    fullWidth
                    required
                    error={errPhone}
                    type="text"
                    helperText={helperPhone}
                    placeholder={getLocalizeText("dialogs/main", "phone", getLanguage(location))}
                    value={phone}
                    onChange={changePhone}
                    className={`${errPhone ? "error" : ""} ${phoneSuccess ? "success" : ""}`}
                />
            </div>
        </div>

        <div className="row">
            <div className="rules">
                <FormControlLabel
                    error={errRule}
                    helperText={helperRule}
                    control={<Checkbox checked={rule} onChange={changeRule} />}
                    label={getRuleCheckboxLabel(getLocalizeText, getLanguage, location)}
                    className={`${errRule ? "error" : ""} ${ruleSuccess ? "success" : ""}`}
                />
            </div>
        </div>
        <Button
            className="btn"
            variant="contained"
            onClick={onSend}
        >
            {getLocalizeText("components/demo", "getFile", getLanguage(location))}
        </Button>
    </div>
}

export default GeneralForm;
