import React from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { getLanguage, getLocalizeText } from "../../misc/localization";
import { ReactComponent as Demo } from "../../assets/imgs/demo.svg";
import GeneralForm from "../forms/GeneralForm";

const GetDemo = (props) => {
    const location = useLocation();

    const onSend = (data) => {
        if (typeof props.onSend === "function")
            props.onSend(data);
    }

    return <div className="GetDemo">
        <div className="GetDemoBlockContent">
            <Demo className="GetDemoImg"/>
            <div className="GetDemoBlockContainer1">
                <h3 className="h3">{getLocalizeText("components/demo", "get", getLanguage(location))}</h3>
                <p className="text-base">{props.subtitle}</p>
                <GeneralForm onSend={onSend} />
            </div>
        </div>
    </div>
}

export default GetDemo;
