/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import MainLayout from "../../layouts/MainLayout";
import "./style.scss";
import ActualTransactions from "../../components/ActualTransactions";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import GetMeMoreInformation from "../../components/dialogs/GetMeMoreInformation";
import SearchBar3 from "../../components/SearchBar3";
import SearchTags from "../../components/SearchTags";
import { useInViewport } from 'react-in-viewport';
import { api_getDomsContent } from "../../redux/actions/domContent";
import { getAnimationNumberDelay, isDesktop, isMobile, isTablet } from "../../misc/dom";
import { api_getSettingByCode } from "../../redux/actions/settings";
import Partners from "../../components/Partners";
import { getLanguage, getLocalizeDomContent, getLocalizeSliderSetting, getLocalizeText } from "../../misc/localization";
import img_ship from "../../assets/imgs/headerShip.png";
import icn_glob from "../../assets/icons/glob.svg";
import icn_time from "../../assets/icons/time.svg";
import icn_shield from "../../assets/icons/shield.svg";
import img_ship2 from "../../assets/imgs/ship2.png";
import PartnersHorizontal from "../../components/PartnersHorizontal";
import { ReactComponent as IconLinkBtnArrow } from "../../assets/icons/linkBtnArrow.svg";


const MainPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const ref_exp = useRef(null);
    const ref_exp_1 = useRef(null);
    const ref_exp_2 = useRef(null);
    const ref_exp_3 = useRef(null);
    const ref_exp_4 = useRef(null);
    
    const ref_searchLeft = useRef(null);
    const ref_searchRight = useRef(null);
    const ref_actualTransactions = useRef(null);
    const ref_profits = useRef(null);
    const ref_profits_b1 = useRef(null);
    const ref_profits_b2 = useRef(null);
    const ref_profits_b3 = useRef(null);
    const ref_exp_text = useRef(null);
    const ref_useful = useRef(null);
    const ref_partners = useRef(null);
    const ref_partners_text = useRef(null);
    const ref_partneref_carousel = useRef(null);

    const expVP = useInViewport(ref_exp);
    const profitVP = useInViewport(ref_profits);
    const usefulVP = useInViewport(ref_useful);
    const partnersVP = useInViewport(ref_partners);

    const [openGetMeMoreInformation, setOpenGetMeMoreInformation] = useState(false);
    const [banner, setBanner] = useState(null);
    const [domsContent, setDomsContent] = useState([]);
    const [expAnimationStarted, setExpAnimationStarted] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [isProfitsAnimated, setIsProfitsAnimated] = useState(false);
    const [isUsefulAnimated, setIsUsefulAnimated] = useState(false); 
    const [isPartnersAnimated, setIsPartnersAnimated] = useState(false); 

    useLayoutEffect(() => {
        getDomsContent();
        startDomAnimations();

        window.addEventListener("scroll", expTextScroll);

        return () => {
            window.removeEventListener("scroll", expTextScroll);
        }
    }, []);

    useEffect(() => {
        if (Array.isArray(domsContent) && domsContent.length && !expAnimationStarted) 
            resetExpDomsForAnimation();
    }, [domsContent]);

    useEffect(() => {
        if (Array.isArray(domsContent) && domsContent.length && expVP.inViewport && !expAnimationStarted) {
            setExpAnimationStarted(true);
            startExpAnimation();
        }
    }, [expVP.inViewport, domsContent]);

    useEffect(() => {
        if (!isProfitsAnimated && profitVP.inViewport) {
            ref_profits.current.style.top = "0px";
            ref_profits_b1.current.style.top = "0px";
            ref_profits_b2.current.style.top = "0px";
            ref_profits_b3.current.style.top = "0px";

            ref_profits.current.style.opacity = "1";
            ref_profits_b1.current.style.opacity = "1";
            ref_profits_b2.current.style.opacity = "1";
            ref_profits_b3.current.style.opacity = "1";

            setIsProfitsAnimated(true);
        }
    }, [profitVP.inViewport]);

    useEffect(() => {
        if (!isUsefulAnimated && usefulVP.inViewport) {
            ref_useful.current.style.top = "0px";
            setIsUsefulAnimated(true);
        }
    }, [usefulVP.inViewport]);

    useEffect(() => {
        if (!isPartnersAnimated && partnersVP.inViewport) {
            ref_partners_text.current.style.top = "0px";
            ref_partneref_carousel.current.style.opacity = "1";
            setIsPartnersAnimated(true);
        }
    }, [partnersVP.inViewport]);

    const startDomAnimations = () => {
        ref_searchLeft.current.style.left = "0px";
        ref_searchLeft.current.style.opacity = "1";

        ref_searchRight.current.style.opacity = "1";
        if (isTablet)
            ref_searchRight.current.style.right = "-100px";
        if (isMobile) 
            ref_searchRight.current.style.right = "-82px";
        if (isDesktop)
            ref_searchRight.current.style.right = "0px";
        ref_actualTransactions.current.style.top = "0px";
    }

    const resetExpDomsForAnimation = async () => {
        const animationTime = await getAnimationTime("expBlockAnimationTime");
        if (animationTime) {
            if (domsContent.some(x => x.code === "exp1")) {
                ref_exp_1.current.innerText = 0;
            }
            if (domsContent.some(x => x.code === "exp2")) {
                ref_exp_2.current.innerText = 0;
            }
            if (domsContent.some(x => x.code === "exp3")) {
                ref_exp_3.current.innerText = 0;
            }
            if (domsContent.some(x => x.code === "exp4")) {
                ref_exp_4.current.innerText = 0;
            }
        }
    }

    const getDomsContent = async () => {
        setDomsContent(await api_getDomsContent());
    }

    const expTextScroll = () => {
        const scrollFrom = ref_exp.current.offsetTop - 100;
        const scrollTo = scrollFrom + ref_exp.current.clientHeight - 270;
        if (isDesktop && window.scrollY >= scrollFrom && window.scrollY <= scrollTo) {
            ref_exp_text.current.style.transition = "none";
            ref_exp_text.current.style.top = `${window.scrollY - scrollFrom}px`;
        }
    }

    const getAnimationTime = async (code) => {
        let animationTime = 3000;
        const dbAnimationTime = await api_getSettingByCode(code);
        if (dbAnimationTime && dbAnimationTime.value) {
            animationTime = parseInt(dbAnimationTime.value, 10) >= 0 ? parseInt(dbAnimationTime.value, 10) : animationTime;
        }
        return animationTime;
    }

    const startExpAnimation = async () => {
        if (isDesktop)
            ref_exp_text.current.style.top = "0px";

        if (Array.isArray(domsContent)) {
            const animationTime = await getAnimationTime("expBlockAnimationTime");

            if (animationTime) {
                const dcExp1 = domsContent.find(x => x.code === "exp1");
                if (dcExp1) {
                    const value = parseInt(dcExp1.value, 10);
                    if (value > 0) {
                        const {delay, added} = getAnimationNumberDelay(value, animationTime);
                        animationIteration(ref_exp_1, value, delay, added);
                    }
                }

                const dcExp2 = domsContent.find(x => x.code === "exp2");
                if (dcExp2) {
                    const value = parseInt(dcExp2.value, 10);
                    if (value > 0) {
                        const {delay, added} = getAnimationNumberDelay(value, animationTime);
                        animationIteration(ref_exp_2, value, delay, added);
                    }
                }

                const dcExp3 = domsContent.find(x => x.code === "exp3");
                if (dcExp3) {
                    const value = parseInt(dcExp3.value, 10);
                    if (value > 0) {
                        const {delay, added} = getAnimationNumberDelay(value, animationTime);
                        animationIteration(ref_exp_3, value, delay, added);
                    }
                }

                const dcExp4 = domsContent.find(x => x.code === "exp4");
                if (dcExp1) {
                    const value = parseInt(dcExp4.value, 10);
                    if (value > 0) {
                        const {delay, added} = getAnimationNumberDelay(value, animationTime);
                        animationIteration(ref_exp_4, value, delay, added);
                    }
                }
            }
        }
        
    }

    const animationIteration = (ref, last, delay, added) => {
        let curVal = parseInt(ref.current.innerText, 10);
        if (curVal < last) {
            ref.current.innerText = curVal + added > last ? last : curVal + added;
            if (curVal < last) {
                setTimeout(() => {
                    animationIteration(ref, last, delay, added);
                }, delay);
            }
        }
    }
    
    const onSearch = (POL, POD) => {
        navigate(`search?pol=${POL.label}&pod=${POD.label}`);
    }

    const onCloseGetMeMoreInformation = () => {
        setOpenGetMeMoreInformation(false);
    }

    const onClickTab = (index) => {
        setActiveTab(index);
    }

    const goToAbout = () => {
        navigate("about");
    }

    const goToCarrier = () => {
        navigate("carrier");
    }

    return <div className="MainPage">
        <MainLayout fixedHeader={true}>
            <Helmet>
                <title>{getLocalizeText("pages/main", "meta.title", getLanguage(location))}</title>
                <meta name="description" content={getLocalizeText("pages/main", "meta.description", getLanguage(location))} />
            </Helmet>

            <div className="content">
                <div className="searchBar">
                    <div ref={ref_searchLeft} className="textBlock">
                        <div className="title">{getLocalizeText("pages/main", "search.title", getLanguage(location))}</div>
                        {getLanguage(location) === "tr"
                            ? <>
                                <div className="description">{getLocalizeText("pages/main", "search.description", getLanguage(location))}</div>
                                <div className="description">{getLocalizeText("pages/main", "search.description2", getLanguage(location))}</div>
                            </>
                            : <div className="description">{getLocalizeText("pages/main", "search.description", getLanguage(location))}</div>
                        }
                    
                        <div className="search">
                            <div className="tabs">
                                <div className={`tab ${activeTab === 0 ? "active" : ""}`} onClick={() => onClickTab(0)}>{getLocalizeText("pages/main", "search.schedule", getLanguage(location))}</div>
                            </div>
                            <div className="tabsContent">
                                <div className={`tabContent ${activeTab !== 0 ? "hide" : ""}`}>
                                    <SearchBar3 onSearch={onSearch} noSearch={true} />
                                    <SearchTags onClick={onSearch} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div ref={ref_searchRight} className="imgBlock">
                        <img src={img_ship} alt="ship" />
                    </div>
                </div>

                <div ref={ref_actualTransactions} className="actualTransactions">
                    <ActualTransactions />
                </div>

                <div ref={ref_profits} className="profits">
                    <h2>{getLocalizeText("pages/main", "profits.title", getLanguage(location))}</h2>

                    <div className="blocks">
                        <div ref={ref_profits_b1} className="block b1">
                            <div className="icon">
                                <img src={icn_glob} alt="icon" />
                            </div>
                            <div className="title">
                                {getLocalizeText("pages/main", "profits.1.title", getLanguage(location))}
                            </div>
                            <div className="description">
                                {getLocalizeText("pages/main", "profits.1.description", getLanguage(location))}
                            </div>
                        </div>
                        <div ref={ref_profits_b2} className="block b2">
                            <div className="icon">
                                <img src={icn_time} alt="icon" />
                            </div>
                            <div className="title">
                                {getLocalizeText("pages/main", "profits.2.title", getLanguage(location))}
                            </div>
                            <div className="description">
                                {getLocalizeText("pages/main", "profits.2.description", getLanguage(location))}
                            </div>
                        </div>
                        <div ref={ref_profits_b3} className="block b3">
                            <div className="icon">
                                <img src={icn_shield} alt="icon" />
                            </div>
                            <div className="title">
                                {getLocalizeText("pages/main", "profits.3.title", getLanguage(location))}
                            </div>
                            <div className="description">
                                {getLocalizeText("pages/main", "profits.3.description", getLanguage(location))}
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={ref_exp} className="exp">
                    <div className="expContainer">
                        <div ref={ref_exp_text} className="descriptionContainer">
                            {!isMobile 
                                ? <h2>{getLocalizeText("pages/main", "exp.title", getLanguage(location))}</h2>
                                : <h2>{getLocalizeText("pages/main", "exp.titleMobile.1", getLanguage(location))}<br />{getLocalizeText("pages/main", "exp.titleMobile.2", getLanguage(location))}</h2>
                            }

                            <div className="description">
                                {getLocalizeText("pages/main", "exp.description", getLanguage(location))}
                            </div>
                            <div className="buttonContainer">
                                <div className="linkBtn" onClick={goToAbout}>{getLocalizeText("pages/main", "exp.descriptionLink", getLanguage(location))} <IconLinkBtnArrow className="icon"/></div>
                            </div>
                        </div>

                        <div className="expBlocks">
                            <div className="expBlock">
                                <div ref={ref_exp_1} className="number exp1Num">{Array.isArray(domsContent) && domsContent.find(x => x.code === "exp1")?.value || 0}</div>
                                <div className="text">{Array.isArray(domsContent) && domsContent.find(x => x.code === "exp1") ? getLocalizeDomContent(domsContent.find(x => x.code === "exp1"), location) : ""}</div>    
                            </div>
                            <div className="expBlock">
                                <div ref={ref_exp_2} className="number exp1Num">{Array.isArray(domsContent) && domsContent.find(x => x.code === "exp2")?.value || 0}</div>
                                <div className="text">{Array.isArray(domsContent) && domsContent.find(x => x.code === "exp2") ? getLocalizeDomContent(domsContent.find(x => x.code === "exp2"), location) : ""}</div>    
                            </div>
                            <div className="expBlock">
                                <div ref={ref_exp_3} className="number exp2Num">{Array.isArray(domsContent) && domsContent.find(x => x.code === "exp3")?.value || 0}</div>
                                <div className="text">{Array.isArray(domsContent) && domsContent.find(x => x.code === "exp3") ? getLocalizeDomContent(domsContent.find(x => x.code === "exp3"), location) : ""}</div>    
                            </div>
                            <div className="expBlock">
                                <div ref={ref_exp_4} className="number exp3Num">{Array.isArray(domsContent) && domsContent.find(x => x.code === "exp4")?.value || 0}</div>
                                <div className="text">{Array.isArray(domsContent) && domsContent.find(x => x.code === "exp4") ? getLocalizeDomContent(domsContent.find(x => x.code === "exp4"), location) : ""}</div>    
                            </div>
                        </div>
                    </div>
                </div>

                <div ref={ref_useful} className="useful">
                    <h2>{getLocalizeText("pages/main", "profits2.title", getLanguage(location))}</h2>
                    <div className="blocks">
                        <div className="block">
                            <div className="title">{getLocalizeText("pages/main", "profits2.blocks.1.title", getLanguage(location))}</div>
                            <div className="description">{getLocalizeText("pages/main", "profits2.blocks.1.description", getLanguage(location))}</div>
                        </div>
                        <div className="block img">
                            <img src={img_ship2} alt="ship" />
                        </div>
                        <div className="block">
                            <div className="title">{getLocalizeText("pages/main", "profits2.blocks.2.title", getLanguage(location))}</div>
                            <div className="description">{getLocalizeText("pages/main", "profits2.blocks.2.description", getLanguage(location))}</div>
                        </div>
                        <div className="block">
                            <div className="title">{getLocalizeText("pages/main", "profits2.blocks.3.title", getLanguage(location))}</div>
                            <div className="description">{getLocalizeText("pages/main", "profits2.blocks.3.description", getLanguage(location))}</div>
                        </div>
                    </div>
                </div>

                <div ref={ref_partners} className="partners">
                    <div ref={ref_partners_text} className="partnersText">
                        <h2>{getLocalizeText("pages/main", "partners.title", getLanguage(location))}</h2>
                        <div className="description">
                            {getLocalizeText("pages/main", "partners.description", getLanguage(location))}
                            <br /><br />
                            {getLocalizeText("pages/main", "partners.description2", getLanguage(location))}
                        </div>
                        {/* @TODO: скрытие в рамках GPNSLGSTC-798 */}
                        {/* <div className="linkBtn" onClick={goToCarrier}>
                            {getLocalizeText("pages/main", "partners.link", getLanguage(location))}
                            <IconLinkBtnArrow className="icon" />
                        </div> */}
                    </div>
                    <div ref={ref_partneref_carousel} className="partnersCarousel">
                        {!isMobile
                            ? <Partners />
                            : <PartnersHorizontal />
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
        <GetMeMoreInformation banner={banner} open={openGetMeMoreInformation} onClose={onCloseGetMeMoreInformation} />
    </div>
}

export default MainPage;