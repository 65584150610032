/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useLocation } from "react-router-dom";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import Error404Page from "./pages/Error404Page";
import MainPage from "./pages/MainPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import SearchPage from "./pages/SearchPage";
import UploadRoutesPage from "./pages/admin/UploadRoutesPage";
import CarrierPage from "./pages/CarrierPage";
import { api_getCities, api_getCompanies, api_getPorts, api_getVersion, api_searchInit } from "./redux/actions";
import SearchLogPage from "./pages/admin/SearchLogPage";
import AdminLoginPage from "./pages/admin/AdminLoginPage";
import AdminCarouselPage from "./pages/admin/AdminCarouselPage";
import AdminMailTemplates from "./pages/admin/AdminMailTemplatesPage";
import AdminPage from "./pages/admin/AdminPage";
import AdminSettingsPage from "./pages/admin/AdminSettingsPage";
import { api_getSettings_store } from "./redux/actions/settings";
import AdminRoutesPage from "./pages/admin/AdminRoutesPage";
import { api_getTags, api_getTagTypes } from "./redux/actions/tag";
import AdminTagsPage from "./pages/admin/AdminTagsPage";
import AdminSearchTagsPage from "./pages/admin/AdminSearchTagsPage";
import LineRatePage from "./pages/LineRatePage";
import AdminLineRatePage from "./pages/admin/AdminLineRatePage";
import RequestLogPage from "./pages/admin/RequestLogPage";
import AdminDomContentPage from "./pages/admin/AdminDomContentPage";
import AdminCompaniesPage from "./pages/admin/AdminCompaniesPage";
import AdminUsersPage from "./pages/admin/AdminUsersPage";
import AdminBannerPage from "./pages/admin/AdminBannerPage";
import AnalyticsPage from "./pages/AnalyticsPage";
import SuccessPage from "./pages/SuccessPage";
import SeaFreightPage from "./pages/SeaFreightPage";

let firstInit = true;

const AppRoutes = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    useEffect(() => {
        setUtm();
    }, []);

    useEffect(() => {
        window.scrollTo({top: 0});
    }, [location]);

    useEffect(() => {
        if (firstInit) {
            firstInit = false;
            init();
        }
    }, []);

    const init = async () => {
        try {
            api_getVersion();
            dispatch(api_searchInit());
            dispatch(api_getPorts());
            dispatch(api_getCities());
            dispatch(api_getCompanies());
            dispatch(api_getSettings_store());
            dispatch(api_getTags());
            dispatch(api_getTagTypes());
        }
        catch(err) {
            console.log(err);
        }
    }

    const setUtm = async () => {
        const params = new URLSearchParams(window.location.search);
        const utm_source = params.get("utm_source") || "";
        const utm_medium = params.get("utm_medium") || "";
        const utm_campaign = params.get("utm_campaign") || "";
        const utm_content = params.get("utm_content") || "";
        const utm_term = params.get("utm_term") || "";

        const fullUrl = window.location.href;
        const url = window.location.origin + window.location.pathname;

        localStorage.setItem("fullUrl", fullUrl);
        localStorage.setItem("url", url);
        localStorage.setItem("utm_source", utm_source);
        localStorage.setItem("utm_medium", utm_medium);
        localStorage.setItem("utm_campaign", utm_campaign);
        localStorage.setItem("utm_content", utm_content);
        localStorage.setItem("utm_term", utm_term);
    }

    const host = window.location.hostname;

    if (host.split(".")[0].toLowerCase().trim() === "top")
        return <Routes>
            <Route path="/" element={<LineRatePage />} />
            <Route path="success" element={<SuccessPage />}  />
            <Route path="/en">
                <Route index element={<LineRatePage />} />
                <Route path="success" element={<SuccessPage />}  />
            </Route>
            <Route path="/tr">
                <Route index element={<LineRatePage />} />
                <Route path="success" element={<SuccessPage />}  />
            </Route>
            <Route path="*" element={<RedirectToApp />}  />
        </Routes>;

    return  <Routes>
        <Route path="/">
            <Route index element={<MainPage />} />
            <Route path="contact" element={<ContactPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="search" element={<SearchPage />} />
            {/* @TODO: скрытие в рамках GPNSLGSTC-798 */}
            {/* <Route path="carrier" element={<CarrierPage />} /> */}
            <Route path="analytics" element={<AnalyticsPage />} />
            <Route path="privacyPolicy" element={<PrivacyPolicyPage />} />
            <Route path="searchLog" element={<SearchLogPage />} />
            <Route path="lineRate" element={<LineRatePage />} />
            <Route path="success" element={<SuccessPage />}  />
            {/* @TODO: скрытие в рамках GPNSLGSTC-798 */}
            {/* <Route path="seafreight" element={<SeaFreightPage />}  /> */}

            <Route path="/en">
                <Route index element={<MainPage />} />
                <Route path="about" element={<AboutPage />} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="search" element={<SearchPage />} />
                <Route path="analytics" element={<AnalyticsPage />} />
                {/* @TODO: скрытие в рамках GPNSLGSTC-798 */}
                {/* <Route path="carrier" element={<CarrierPage />} /> */}
                <Route path="lineRate" element={<LineRatePage />} />
                <Route path="privacyPolicy" element={<PrivacyPolicyPage />} />
                <Route path="success" element={<SuccessPage />}  />
                {/* @TODO: скрытие в рамках GPNSLGSTC-798 */}
                {/* <Route path="seafreight" element={<SeaFreightPage />}  /> */}
            </Route>

            <Route path="/tr">
                <Route index element={<MainPage />} />
                <Route path="about" element={<AboutPage />} />
                <Route path="contact" element={<ContactPage />} />
                <Route path="search" element={<SearchPage />} />
                <Route path="analytics" element={<AnalyticsPage />} />
                {/* @TODO: скрытие в рамках GPNSLGSTC-798 */}
                {/* <Route path="carrier" element={<CarrierPage />} /> */}
                <Route path="lineRate" element={<LineRatePage />} />
                <Route path="privacyPolicy" element={<PrivacyPolicyPage />} />
                <Route path="success" element={<SuccessPage />}  />
                {/* @TODO: скрытие в рамках GPNSLGSTC-798 */}
                {/* <Route path="seafreight" element={<SeaFreightPage />}  /> */}
            </Route>

            <Route path="admin">
                <Route index element={<AdminPage />} />
                <Route path="searchLogPage" element={<SearchLogPage />} />
                <Route path="requestLogPage" element={<RequestLogPage />} />
                <Route path="login" element={<AdminLoginPage />} />
                <Route path="upload" element={<UploadRoutesPage />} />
                <Route path="routes" element={<AdminRoutesPage />} />
                <Route path="companies" element={<AdminCompaniesPage />} />
                <Route path="tags" element={<AdminTagsPage />} />
                <Route path="searchTags" element={<AdminSearchTagsPage />} />
                <Route path="carousel" element={<AdminCarouselPage />} />
                <Route path="banners" element={<AdminBannerPage />} />
                <Route path="mailTemplates" element={<AdminMailTemplates />} />
                <Route path="settings" element={<AdminSettingsPage />} />
                <Route path="lineRate" element={<AdminLineRatePage />} />
                <Route path="domContent" element={<AdminDomContentPage />} />
                <Route path="users" element={<AdminUsersPage />} />
            </Route>
        </Route>
        <Route path="*" element={<Error404Page />}  />
    </Routes>
}

const RedirectToApp = () => {
    window.location.href = window.location.href.replace("top.", "");
    return "";
}

export default AppRoutes;
