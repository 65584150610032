import React, { memo } from "react";
import MailHeader from "../components/MailHeader";
import MailFooter from "../components/MailFooter";

const MailCarriersRatingToCustomer = memo((props) => {
    return (
      <div style={{ width: "600px", backgroundColor: "#FFF" }}>
        <MailHeader />

        <div style={{ padding: "64px 60px" }}>
          <div
            style={{
              fontFamily: ["Montserrat", "Arial"],
              fontWeight: "600",
              fontSize: "18px",
              lineHeight: "28px",
              color: "#0F172A",
            }}
          >
            Благодарим за обращение в SeaLogic!
          </div>

          <p
            style={{
              fontFamily: ["Montserrat", "Arial"],
              fontWeight: "400",
              fontSize: "12px",
              lineHeight: "16px",
              color: "#334155",
              marginTop: "12px",
              marginBottom: "0px"
            }}
          >
              Вам предоставлен файл с демонстрационными данными по судозаходам!
          </p>
          <p
            style={{
                fontFamily: ["Montserrat", "Arial"],
                fontWeight: "400",
                fontSize: "12px",
                lineHeight: "16px",
                color: "#334155",
                marginTop: "12px",
                marginBottom: "0px"
            }}
          >
              Если у вас появились вопросы, или вы хотите получать актуальные данные на ежемесячной основе, свяжитесь с нами:
            по почте <a
              href="mailto:info@sealogic.io"
              target="_blank"
              rel=" noopener noreferrer"
              style={{
                  fontFamily: ["Montserrat", "Arial"],
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#2563EB",
              }}
            >info@sealogic.io</a> или оставьте заявку на сайте <a
              href="https://sealogic.io/"
              target="_blank"
              rel=" noopener noreferrer"
              style={{
                  fontFamily: ["Montserrat", "Arial"],
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#2563EB",
              }}
          >sealogic.io</a>
          </p>

          <div
            style={{
              marginTop: "44px",
              fontFamily: ["Montserrat", "Arial"],
              fontSize: "9px",
              lineHeight: "14px",
              color: "#94A3B8",
            }}
          >
            *Данное письмо сформировано автоматически. Отвечать на него не
            нужно.
          </div>
        </div>

        <MailFooter />
      </div>
    );
});

export default MailCarriersRatingToCustomer;
