import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";
import "./style.scss";
import { useLocation } from "react-router-dom";
import { getLanguage, getLocalizeText } from "../../../misc/localization";
import {ReactComponent as CloseIcon} from '../../../assets/icons/close.svg';

const InfoDialog = (props) => {
    const location = useLocation();
    const onClose = () => {
        if (typeof props.onClose === "function")
            props.onClose();
    }

    return <Dialog className="dialog InfoDialog" open={props.open || false} onClose={onClose}>
        <DialogTitle>
            {props.title || getLocalizeText("dialogs/info", "success", getLanguage(location))}
            <CloseIcon className="close" onClick={onClose} />
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.content || ""}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button className="btn" variant="contained" onClick={onClose} autoFocus>{getLocalizeText("dialogs/info", "close", getLanguage(location))}</Button>
        </DialogActions>
    </Dialog>
}

export default InfoDialog;
